import React from 'react'
import styled from 'react-emotion'
import { navigate } from "gatsby-link"

import IconLink from "../assets/images/blocks-icon.svg"

const Logo = styled('img')({
  cursor: 'pointer',
  marginRight: '20px',
  marginBottom: 0,
  '@media only screen and (max-width: 900px)': {
    marginRight: 0,
  }
})

const LeftWrapper = styled('div')({
  marginRight: 'auto',
  display: 'flex',
  alignItems: 'center',
  '@media only screen and (max-width: 900px)': {
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 auto 20px auto'
  }
})

const Text = styled('span')({
  fontSize: '13px',
  color: '#808080',
  textAlign: 'center',
  'a': {
    color: '#000000',
    textDecoration: 'none',
    fontWeight: 500,
    cursor: 'pointer',
  },
  'a:hover': {
    textDecoration: 'underline'
  }
})

const FooterWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '30px 0',
  width: 'calc(96vw)',
  margin: '0 auto',
  borderTop: '1px solid #E5E8EF',
  '@media only screen and (max-width: 900px)': {
    flexDirection: 'column',
    justifyContent: 'center'
  }
})

const UpsideDown = styled('span')({
  background: 'transparent',
  padding: '5px',
  cursor: 'default',
  'span': {
    display: 'inline-block',
    transform: 'rotate(-180deg)',
    transition: 'all 0.5s ease',
  },
  '&:hover': {
    transform: 'rotate(0deg)',
    transition: 'all 0.5s ease',
    'span': {
      transform: 'rotate(0deg)',
      transition: 'all 0.5s ease'
    }
  }
})


const FooterLink = styled('span')({
  cursor: 'pointer',
  color: '#000',
  fontWeight: '500',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
})


const Footer = () => (
  <FooterWrapper>
    <LeftWrapper>
      <Logo src={IconLink} alt="Blocks Logo" onClick={() => navigate('/')} />
      <Text>© 2018 Blocks. All rights reserved.</Text>
    </LeftWrapper>
    <Text>
      <UpsideDown><span>Made in Perth, Australia</span></UpsideDown>∙
      <FooterLink onClick={() => navigate('/about/')}>About Us</FooterLink> ∙
      <FooterLink onClick={() => navigate('/privacy/')}>Privacy</FooterLink> ∙
      <a href="mailto:corey@ginnivan.net">Email Us</a>

    </Text>
  </FooterWrapper>
)

export default Footer
