import React from 'react'
import styled from 'react-emotion'
import { navigate } from "gatsby-link"

import LogoLink from "../assets/images/blocks-logotype.svg"

const NavigationWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px',
  justifyContent: 'flex-start',
  padding: '20px',
  width: '100%',
})

const Logo = styled('img')({
  cursor: 'pointer',
  margin: 0
})

// const ButtonCTA = styled('div')({
//   color: '#222',
//   textAlign: 'left',
//   fontSize: '14px',
//   lineHeight: '14px',
//   fontWeight: '600',
//   backgroundColor: '#fff',
//   display: 'flex',
//   width: 'fit-content',
//   height: 'fit-content',
//   borderRadius: '5px',
//   padding: '10px',
//   cursor: 'pointer',
//   position: 'relative',
//   verticalAlign: 'middle',
//   marginLeft: 'auto',
//   transition: 'all 0.3s ease',
//   '&:hover': {
//     backgroundColor: '#EDEDED',
//     transition: 'all 0.3s ease'
//   }
// })

const Navigation = () => (
  <NavigationWrapper>
    <Logo src={LogoLink} alt="Blocks Logo" onClick={() => navigate('/')} />
  </NavigationWrapper>
)

export default Navigation
