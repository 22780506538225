import React from 'react';
import styled from 'react-emotion';
import Footer from '../components/footer';
import Navigation from '../components/navigation';
import Layout from '../layouts';

const Main = styled('div')({
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '@media only screen and (max-width: 700px)': {
    justifyContent: 'flex-start',
  },
})

const Wrapper = styled('div')({
  margin: '0 auto',
  marginTop: '40px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  maxWidth: '700px',
  minHeight: '400px',
  marginBottom: '100px',
  '@media only screen and (max-width: 700px)': {
    margin: '0 20px auto 20px',
  },
})

const Heading = styled('h1')({
  fontSize: '42px',
  lineHeight: '40px',
  color: '#000000',
  fontWeight: '800',
  marginBottom: '40px',
  marginTop: '10px',
  '@media only screen and (max-width: 700px)': {
    fontSize: '20px',
    lineHeight: '30px',
    marginBottom: '20px',
  },
})

const Text = styled('div')({
  fontSize: '22px',
  color: '#808080',
  lineHeight: '30px',
  marginBottom: '40px',
  textAlign: 'left',
  a: {
    color: '#000',
    textDecoration: 'none',
  },
  'a:hover': {
    textDecoration: 'underline',
  },
  '@media only screen and (max-width: 700px)': {
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '20px',
  },
})

const LargeText = styled('div')({
  fontSize: '32px',
  color: '#000000',
  lineHeight: '42px',
  marginBottom: '40px',
  textAlign: 'left',
  '@media only screen and (max-width: 700px)': {
    fontSize: '22px',
    lineHeight: '26px',
    marginBottom: '20px',
  },
})

const SecondPage = () => (
  <Layout>
    <Main>
      <Navigation />
      <Wrapper>
        <Heading>Behind Blocks</Heading>
        <LargeText>
          Time is precious. Noise is everywhere. That doesn’t stop us from
          needing to still make around 30,000 decisions every day. Sounds like a
          lot to keep track of in our own heads, right?
        </LargeText>
        <Text>
          There’s no shortage of productivity tools out there. There are tools
          to manage calendars, projects, to dos and checklists, but nothing that
          quite manages all of these things together visually, without some
          fiddling or repetitive work-arounds required.
        </Text>
        <Text>Repetitive trivial tasks? Aint nobody got time fo’ that.</Text>
        <Text>Blocks is here to solve that.</Text>
        <Text>
          For the longest time, we’ve loved using products like Trello and Asana
          as part of our routines, pairing the power of their tools alongside
          our calendars. We’re simple folk, so we honestly just longed for a
          merge of the two into one, with a few customisations sprinkled in, and
          all would have been perfect.
        </Text>
        <Text>
          Now don’t get us wrong, we’d still highly recommend both of these
          great products; and in fact, 100% suggest that you try them out first
          to see how you go.
        </Text>
        <Text>
          Yep, we just totally recommended not one, but two other products on
          our own product website. Maybe one day a PR person will bop us on the
          noggin for that… a future us problem.
        </Text>
        <Text>
          Honestly speaking, our use case simply desired something a little more
          deliberate to improve the efficiency of that workflow. We figured, if
          this is something we would use, maybe others would find it handy too.
        </Text>
        <Heading>Who’s behind this Block Party?</Heading>
        <Text>
          Just four mates with a common goal to boost our productivity,
          hopefully helping others along the way:
        </Text>
        <Text>
          <a
            href="https://twitter.com/CoreyGinnivan"
            target="_blank"
            rel="noopener noreferrer"
          >
            Corey Ginnivan
          </a>
          <br />
          <a
            href="https://twitter.com/ThomasLeenders"
            target="_blank"
            rel="noopener noreferrer"
          >
            Thomas Leenders
          </a>
          <br />
          <a
            href="https://twitter.com/the_patima"
            target="_blank"
            rel="noopener noreferrer"
          >
            Patima Tantiprasut
          </a>
          <br />
          <a
            href="https://twitter.com/cyruseftos"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cyrus Eftos
          </a>
        </Text>
      </Wrapper>
      <Footer />
    </Main>
  </Layout>
)

export default SecondPage
